import React from 'react'
import './App.css'
import { FaHouseUser, FaHotjar, FaRegAddressCard } from "react-icons/fa";
export default function

    () {
    return (
        <div className='container'>

            <nav className='navBar'>

                <div className='divLogo'>
                    <img src="https://static.wixstatic.com/media/a8015a_4d789262f23a44cb9d7fa05cd74e6012~mv2_d_1772_1252_s_2.png/v1/fill/w_346,h_200,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/logo%20balada%20neon%20novo.png" height={90} />
                </div>

                <div className='listNav'>
                    <ul className='listaNav'>
                        <li> <FaHouseUser size={35} /> HOME</li>
                        <li> <FaHotjar size={35} />Sobre nós</li>
                        <li> <FaRegAddressCard size={35} />Contato</li>
                    </ul>
                </div>
            </nav>
            <nav className='subNavBar'>
                <ul className='listaSubNav'>
                    <li>Eventos</li>
                    <li>Galeria de fotos</li>
                    <li>FAQ</li>
                    <li>Preços</li>
                    <li>Unidades</li>
                </ul>
            </nav>
            <div className='box_1'>
                <div className='overlay'>

                </div>
                <div className='conteudo'>
                    <header>
                    <h1>Formatura Ana Rita</h1>
                    </header>
                    <main>
                    <p>
                       A Formatura dos 3° anos da escola Ana Rita Godinho Pousa acontecera em um chacara.
                    </p>
                    <h2>Valor</h2>
                    <p>
                        O valor total é R$$600,00, podendo pagar a vista ou parcelando até o periodo da formatura.
                    </p>

                    <h2>periodo</h2>
                    <p>
                        A festa tera o seu periodo de sexta até final de domingo, os alunos poderão ficar no local ou sair e voltar quando quiser.
                    </p>

                    <h2>Mais informações</h2>

                    <p>
                        <ul>
                      
                            <li>MOLHERES</li>
                            <li>PRAZER</li>
                            <li>CURTIÇÃO</li>
                        </ul>
                     
                        </p>
                           </main>
                </div>
            </div>
         

            <footer className='navBar footer'>
                <br /><br /><br /><br /><br />
                     <small>&copy; Copyright : Rafael Zimmerman</small>
                    

            </footer>
        </div >
    )
}



